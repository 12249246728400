import React from 'react'
import PostListHorizontal from '../PostListHorizontal'
import Adsense from '../Adsense'
import SocialMedia from '../SocialMedia'
import Subscribe from '../Subscribe'
import './Sidebar.scss'
import { graphql, useStaticQuery } from 'gatsby'

export default function Sidebar({location, siteMeta}) {    
    const suggestedPosts = useStaticQuery(graphql`
    query SuggestedPostsQuery {
      recentMdx: allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { 
          frontmatter: { 
            type: { eq: "post" }
            status: { ne: "draft" } 
          } 
        }
        limit: 6
      ) {
        edges {
          node {
            excerpt
            timeToRead
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              author
              type
              description
              image {
                publicURL
                childImageSharp {
                  gatsbyImageData(width: 960)
                }
              }
            }
          }
        }
      }
      popularMdx: allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {
          fields: { 
            slug: { in: [
              "/spring-boot-spring-security-jwt-mysql-react-app-part-1/",
              "/spring-boot-rest-api-tutorial-with-mysql-jpa-hibernate/",
              "/node-js-express-mongodb-restful-crud-api-tutorial/",
              "/java-8-completablefuture-tutorial/",
              "/spring-boot-websocket-chat-example/"
              ] 
            }
          }
        }
        limit: 6
      ) {
        edges {
          node {
            excerpt
            timeToRead
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              type
              description
              image {
                publicURL
                childImageSharp {
                  gatsbyImageData(width: 960)
                }
              }
            }
          }
        }
      }
    }
  `)

  const posts = suggestedPosts.recentMdx.edges.filter(
    ({ node }) => node.fields.slug !== location.pathname
  )

  const popularPosts =  suggestedPosts.popularMdx.edges.filter(
    ({ node }) => node.fields.slug !== location.pathname
  )

  return (
    <aside className="page-aside">
      <div className="widget">
        <Subscribe siteMeta={siteMeta} />
      </div>
      <div className="widget-ad">
        <Adsense type="sidebar-top" />
      </div>
      <div className="widget">
        <SocialMedia />
      </div>
      <div className="widget">
        <PostListHorizontal posts={posts} title="Recent Articles" siteMeta={siteMeta} />
      </div>
      { popularPosts ? 
      <div className="widget">
        <PostListHorizontal posts={popularPosts} title="Popular Articles" siteMeta={siteMeta} />
      </div>: null
      }
      <div className="widget-ad">
        <Adsense type="sidebar-middle" />
      </div>
      <div className="widget-ad">
        <Adsense type="sidebar-bottom" />
      </div>
    </aside>
  )  
}
