import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../layouts'
import Article from '../../components/Article'
import Sidebar from '../../components/Sidebar'
import SEO from '../../components/SEO'
import Container from '../../components/Container'
import Disqus from '../../components/Disqus'
import './blog-post.scss'

class BlogPostTemplate extends React.Component {
  render () {
    const post = this.props.data.mdx

    return (
      <Layout
        location={this.props.location}
        siteMeta={this.props.data.site.siteMetadata}
      >
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={post.frontmatter.image ? post.frontmatter.image.publicURL: null}
          pathname={post.fields.slug}
          article={true}
        />
        <main className="blog-main-content">
          <Container>
            <div className="primary-content">
              <Article
                post={post}
                siteMeta={this.props.data.site.siteMetadata}
              />
              <Disqus
                location={this.props.location}
                disqusShortname={
                  this.props.data.site.siteMetadata.disqusShortname
                }
              />
            </div>
            <div className="secondary-content">
              <Sidebar
                location={this.props.location}
                siteMeta={this.props.data.site.siteMetadata}
              />
            </div>
          </Container>
        </main>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        name
        title
        description
        author
        disqusShortname
        mailchimpEndpoint
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      timeToRead
      body
      fields {
        slug
      }
      frontmatter {
        title
        author
        description
        date(formatString: "MMMM DD, YYYY")
        categories        
        image {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 960)
          }
        }
      }
    }    
  }
`
